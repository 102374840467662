import { QuotaConstants } from './../constants/quota-constants';
import { localService } from './../services/localStorageService';
import { classSessionActions } from '../actions/class-session.action';
import webviewMessenger from '../services/webviewMessenger';
import { AiQuizQuestionInterface, AiQuizSlideInterface, SlideIdAndTextInterface } from '../interfaces/aiQuiz.interface';
import { AiLanguages } from '../constants/ai-languages';
import UserInterface from '../interfaces/user-interface';
import { store } from './store';

export enum QT {
    MC = 'mc',
    FB = 'fb',
    SA = 'sa',
}

export enum BT {
    REMEMBER = 'Remember',
    UNDERSTAND = 'Understand',
    APPLY = 'Apply',
    ANALYZE = 'Analyze',
    EVALUATE = 'Evaluate',
    CREATE = 'Create',
}

export const initializeQuestionLanguage = (user: UserInterface) => {
    const questionLanguageSaved = user.userPreferences?.aiQuizSettings?.questionLanguage;
    if (questionLanguageSaved) return questionLanguageSaved;
    const interfaceLanguage = AiLanguages.find((lang) => lang.code === user.userPreferences?.language)?.value;
    if (interfaceLanguage) return interfaceLanguage;
    return 'English';
};

export const generatePrompt = (
    currentSlideText: string,
    currentSlideNote: string,
    btLevels: string[],
    questionTypeToggle: boolean[],
    chosenLanguage: string,
): string => {
    let context = '';
    if (currentSlideText.length < 200 && currentSlideNote?.trim()?.length > 0)
        context = `Given the following text from a PowerPoint slide: [${currentSlideText}], and the following notes for the slide: [${currentSlideNote.slice(
            0,
            1000,
        )}]`;
    else context = `Given the following text from a PowerPoint slide: [${currentSlideText.slice(0, 1000)}]`;
    const questionTypes = [
        {
            name: 'multiple choice',
            format: `"question" (string), "options" (string array), "correct" (number), "bloomTaxonomyLevel" (string) and there should be only one correct answer, which is randomly placed among the options`,
            use: questionTypeToggle[0],
        },
        {
            name: 'fill in the blank',
            format: `"question" (string), "correctAnswer" (string), "bloomTaxonomyLevel" (string) and use ______ to represent the blank`,
            use: questionTypeToggle[1],
        },
        {
            name: 'open ended',
            format: `"question" (string), "answer" (string), "bloomTaxonomyLevel" (string)`,
            use: questionTypeToggle[2],
        },
    ];

    let questionTypeInstruction = '';
    const usedQuestionTypesCount = questionTypes.filter((type) => type.use).length;
    if (usedQuestionTypesCount === 0) return '';
    if (usedQuestionTypesCount === 1) {
        const usedType = questionTypes.find((type) => type.use);
        questionTypeInstruction = `generate five ${
            usedType?.name
        } quiz questions on the following Bloom's Taxonomy levels: ${btLevels.join(
            ', ',
        )}. Each generated question is an object with the following properties: ${usedType?.format}.`;
    } else {
        const usedTypes = questionTypes.filter((type) => type.use);
        const usedTypesNames = usedTypes.map((type) => type.name);
        questionTypeInstruction = `generate a mixture of five ${usedTypesNames.join(
            ' and ',
        )} quiz questions on the following Bloom's Taxonomy levels: ${btLevels.join(
            ', ',
        )}. Each question type should have at least one question. Each question is an object.`;
        for (let i = 0; i < usedTypes.length; i++) {
            const type = usedTypes[i];
            questionTypeInstruction += ` If the question type is ${type.name}, it should have the following properties: ${type.format}.`;
        }
    }
    const prompt = `${context}, ${questionTypeInstruction} The questions must be set in the language of ${
        chosenLanguage || 'English'
    }. Provide good quality answers to each question and never provide blank answers. Do not generate too easy questions. The result should be in json format.`;
    console.log(prompt);
    return prompt;
};

export const generateQuestionsFromSlide = (
    dispatch: any,
    currentSlideData: SlideIdAndTextInterface,
    btLevels: BT[],
    questionTypes: QT[],
    questionLanguage: string,
) => {
    const prompt = generatePrompt(
        currentSlideData.currentSlideText,
        currentSlideData.currentSlideNote,
        btLevels,
        [
            isQuestionTypeSelected(questionTypes, QT.MC),
            isQuestionTypeSelected(questionTypes, QT.FB),
            isQuestionTypeSelected(questionTypes, QT.SA),
        ],
        questionLanguage,
    );

    if (!prompt) return;
    dispatch(classSessionActions.generateAiQuiz(currentSlideData.currentSlideId, prompt));
};

export const isQuestionTypeSelected = (questionTypes: QT[], type: QT) => {
    return questionTypes.includes(type);
};

export const generateMcqLabel = (index: number) => {
    return String.fromCharCode(index + 65);
};

export const removeExistingMcqLabel = (option: string) => {
    return option.replace(/^[A-Za-z]\)\s/, '').replace(/^[A-Za-z]\.\s/, ''); // example: A. option 1 OR A) option 1 -> option 1
};

export const saveQuestionAsSlide = (questionData: AiQuizQuestionInterface) => {
    let questionType = '';
    let answer = '';

    if (questionData.options) {
        questionType = 'mc';
        answer = questionData.correct?.toString() || '';
    } else if (questionData.correctAnswer) {
        questionType = 'fb';
        answer = questionData.correctAnswer || '';
    } else {
        questionType = 'sa';
        answer = questionData.answer || '';
    }

    const payload: AiQuizSlideInterface = {
        questionType,
        question: questionData.question,
        answer,
    };

    if (questionData.options) payload.options = questionData.options.map((option) => removeExistingMcqLabel(option));
    webviewMessenger.insertAiQuizSlide(payload);
};

export const isFillBlanks = (currentQuestion: AiQuizQuestionInterface) => {
    return Object.keys(currentQuestion).includes('correctAnswer') ? true : false;
};

export const isFillBlanksOrShortAnswer = (currentQuestion: AiQuizQuestionInterface) => {
    return Object.keys(currentQuestion).includes('correctAnswer') || Object.keys(currentQuestion).includes('answer')
        ? true
        : false;
};

export const calculateTotalAiQuestionsGenerated = (user: UserInterface) => {
    const fromPreviousSessions = user.userStats.aiQuestionsGenerated || 0;
    const fromCurrentSession = store.getState().classSession?.aiQuestionsGenerated || 0;
    const fromCurrentSlideshow = localService.getToolbarActions()?.generateAiQuestion || 0;
    // console.log(fromPreviousSessions, fromCurrentSession, fromCurrentSlideshow);
    const total = fromPreviousSessions + fromCurrentSession + fromCurrentSlideshow;
    return Math.min(total, QuotaConstants.BASIC_USER_AI_CREDITS);
};
