import VotingCount from '../../../components/Common/VotingCount';
import { Typography } from '@mui/material';
import MultiMediaActivityResponseFooter from '../MultiMediaActivityResponseFooter';
import {
    calculateShortAnswerModalTextCssName,
    checkAndMarkMatchingHtml,
    linkify,
} from '../../../helpers/activityHelpers';
import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { AnimatePresence, motion } from 'framer-motion';
import { SimpleFadeAnimation } from '../../../constants/animation-configs';
import { TickLottie } from '../../../components/Common/AnimationModules';
import { stringToNumber } from '../../../helpers/utils';
import { findCenter } from '../../../helpers/animationHelpers';
import { useRef } from 'react';
import { utilConstants } from '../../../constants/utils.constants';

import filter from '../../../services/badwords.service';

interface ShortAnswerModalInterface {
    choosedResponse: ActivityResponseInterface;
    responseIndex: number;
    onResponseIndexDeleted: any;
    searchKeyword: string;
    isVoting: boolean;
    handleGivePointButtonClick: any;
    isAnimating: boolean;
    pointsAdded: number;
}

function ShortAnswerModal({
    choosedResponse,
    responseIndex,
    onResponseIndexDeleted,
    searchKeyword,
    isVoting,
    handleGivePointButtonClick,
    isAnimating,
    pointsAdded,
}: ShortAnswerModalInterface) {
    const boxSizeRef = useRef<any>();

    return (
        <ul className="sky-slider__img-container short_modal">
            <motion.li variants={SimpleFadeAnimation} initial="initial" animate="animate" className="active">
                <div
                    className="big_sh_box"
                    style={{
                        background:
                            utilConstants.SHORT_ANSWER_COLORS[
                                stringToNumber(choosedResponse.responseId) % utilConstants.SHORT_ANSWER_COLORS.length
                            ],
                    }}
                >
                    <div
                        className="overlay_sh_box"
                        style={{
                            background:
                                utilConstants.SHORT_ANSWER_COLORS[
                                    stringToNumber(choosedResponse.responseId) %
                                        utilConstants.SHORT_ANSWER_COLORS.length
                                ],
                        }}
                    ></div>
                    {choosedResponse.voterParticipantIds && (
                        <div className="big_sh_head">
                            <VotingCount response={choosedResponse} />
                        </div>
                    )}
                    <div ref={boxSizeRef} className="big_sh_content">
                        {isAnimating && <div className="overlayAnimationGlobal show "></div>}
                        <AnimatePresence>
                            {isAnimating && (
                                <TickLottie
                                    key={choosedResponse.responseId}
                                    index={stringToNumber(choosedResponse.responseId)}
                                    xPosition={() => findCenter(boxSizeRef).x}
                                    yPosition={() => findCenter(boxSizeRef).y}
                                    scale="2"
                                />
                            )}
                        </AnimatePresence>
                        <div className="quote">
                            <svg viewBox="0 0 40 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 19.0222C0 15.1484 1.40023 11.4174 4.20069 7.82907C7.04059 4.24075 10.196 1.63106 13.667 0L14.7911 2.44658C12.0301 4.07764 9.66355 6.11646 7.6914 8.56305C5.75869 10.9689 4.79233 13.4154 4.79233 15.9028C4.79233 16.3921 4.93038 16.7591 5.20648 17.0038C5.52203 17.2484 5.91646 17.3707 6.38978 17.3707C8.95358 17.0853 10.3341 16.9426 10.5313 16.9426C11.8329 16.9426 13.2331 17.4319 14.732 18.4105C16.2703 19.3892 17.0394 21.061 17.0394 23.426C17.0394 25.4241 16.4083 27.1163 15.1461 28.5027C13.884 29.8891 12.0893 30.5823 9.76216 30.5823C7.04059 30.5823 4.73317 29.5017 2.8399 27.3406C0.946634 25.1387 0 22.3659 0 19.0222ZM22.9606 19.5115C22.9606 15.1484 24.3805 11.2135 27.2204 7.70674C30.0998 4.15919 33.2355 1.59028 36.6276 0L37.7517 2.44658C34.9907 4.07764 32.6241 6.09607 30.652 8.50188C28.7193 10.8669 27.7529 13.2931 27.7529 15.7805C27.7529 16.229 27.8713 16.6164 28.1079 16.9426C28.3446 17.228 28.7587 17.3707 29.3504 17.3707C31.8747 17.0853 33.2552 16.9426 33.4919 16.9426C34.9513 16.9426 36.391 17.4931 37.8109 18.594C39.2703 19.6542 40 21.2445 40 23.3649C40 25.0367 39.4281 26.6678 38.2842 28.258C37.1798 29.8076 35.3063 30.5823 32.6636 30.5823C29.8237 30.5823 27.4965 29.461 25.6822 27.2183C23.8678 24.9348 22.9606 22.3659 22.9606 19.5115Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                        <div className="shortmodal_content">
                            <Typography
                                variant="body1"
                                component={'span'}
                                style={{ fontSize: '16px' }}
                                className={`size${calculateShortAnswerModalTextCssName(
                                    choosedResponse.responseData,
                                )} shortmodal_paragraph`}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: linkify(
                                            checkAndMarkMatchingHtml(
                                                // filter.clean(`a${choosedResponse.responseData}`).slice(1), // cause the page to lag so commented out
                                                choosedResponse.responseData,
                                                searchKeyword.toString(),
                                            ).replacedHtml,
                                        ),
                                    }}
                                ></div>
                            </Typography>
                        </div>
                    </div>
                    <MultiMediaActivityResponseFooter
                        response={choosedResponse}
                        responseIndex={responseIndex}
                        onResponseIndexDeleted={onResponseIndexDeleted}
                        searchKeyword={searchKeyword}
                        isVoting={isVoting}
                        useFromModal={true}
                        pointsAdded={pointsAdded}
                        onGivePointButtonClick={handleGivePointButtonClick}
                    />
                </div>
            </motion.li>
        </ul>
    );
}

export default ShortAnswerModal;
