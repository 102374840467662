import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './ShortAnswer/ShortAnswer.scss';

import { getActivityFromStore } from '../../helpers/storeHelpers';
import { getActivityComponentClassName } from '../../helpers/activityHelpers';
import webviewMessenger from '../../services/webviewMessenger';

import ActivityResponseInterface from '../../interfaces/activity-response.interface';
import { ActivityPropsInterface } from '../../interfaces/activity-props.interface';
import StoreInterface from '../../interfaces/store.interface';
import { utilConstants } from '../../constants/utils.constants';

import MultiMediaActivityModal from './Modals/MultiMediaActivityModal';
import SearchBox from './components/SearchBox';
import ShortAnswerContent from './ShortAnswer/ShortAnswerContent';
import ImageUploadContent from './ImageUpload/ImageUploadContent';
import SlideDrawingContent from './SlideDrawing/SlideDrawingContent';
import AudioRecordContent from './AudioRecord/AudioRecordContent';
import ShowingResponsesCount from '../../components/Common/ShowingResponsesCount';
import VideoUploadContent from './VideoUpload/VideoUploadContentNew';

interface MultiMediaActivityContentInterface {
    selectedResponses: ActivityResponseInterface[];
    searchKeyword: string;
    onSearch: any;
    isVoting: boolean;
    isVotingNamesHidden: boolean;
    isGivePointsToAll: boolean;
}

export default function MultiMediaActivityContent({
    selectedResponses,
    searchKeyword,
    onSearch,
    isVoting,
    isVotingNamesHidden,
    isGivePointsToAll,
}: MultiMediaActivityContentInterface) {
    const lazyLoadingInitialCount = 20;
    const lazyLoadingIncrement = 10;
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const activity = getActivityFromStore();
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const activityType = activityProps.activityType;
    const [lazyLoadedResponsesCount, setLazyLoadedResponsesCount] = useState(lazyLoadingInitialCount);
    const [lazyLoadedResponses, setLazyLoadedResponses] = useState<ActivityResponseInterface[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalResponseIndex, setModalResponseIndex] = useState(0);
    const [choosedResponse, setChoosedResponse] = useState<ActivityResponseInterface>();
    const [isFetchingMore, setIsFetchingMore] = useState(false);

    useEffect(() => {
        const isRunningOrAfterRunningActivity =
            activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY || !activity.activityUser;
        if (isRunningOrAfterRunningActivity) {
            setLazyLoadedResponsesCount(selectedResponses.length);
            setLazyLoadedResponses(selectedResponses);
        } else {
            setLazyLoadedResponses(selectedResponses.slice(0, lazyLoadedResponsesCount));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedResponses, lazyLoadedResponsesCount]);

    useEffect(() => {
        const selectedFirstModalResponse = selectedResponses[modalResponseIndex];
        setChoosedResponse(selectedFirstModalResponse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedResponses]);

    const sortSelectedResponses = (responses: ActivityResponseInterface[]) => {
        if (responses[0]?.voterParticipantIds) return responses.sort(sortFunc);
        else return responses;
    };

    const sortFunc = (a: ActivityResponseInterface, b: ActivityResponseInterface) => {
        if (!a.voterParticipantIds || !b.voterParticipantIds) return 1; //should not happen
        if (a.voterParticipantIds.length === b.voterParticipantIds.length)
            return new Date(a.responseSubmittedOn).getTime() - new Date(b.responseSubmittedOn).getTime();
        return b.voterParticipantIds.length - a.voterParticipantIds.length;
    };

    const handleThumbClick = (response: ActivityResponseInterface, index: number) => {
        webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Opened response in modal`);
        setChoosedResponse(response);
        setModalResponseIndex(index);
        setIsModalOpen(true);
    };

    const handleResponseIndexDeletedFromModal = (index: number) => {
        webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Deleted a response from modal`);
        if (index < selectedResponses.length - 1) {
            setChoosedResponse(selectedResponses[index + 1]);
        } else {
            setChoosedResponse(selectedResponses[0]);
            setModalResponseIndex(0);
        }
    };

    function handleScroll(event: any) {
        if (activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY) return;
        var node = event.target;
        const isNearBottom =
            Math.round(node.scrollHeight) - Math.round(node.scrollTop) <= 1.1 * Math.round(node.clientHeight);

        if (isNearBottom) checkAndShowMoreResponses();
    }

    const checkAndShowMoreResponses = () => {
        if (lazyLoadedResponses.length < selectedResponses.length) {
            setIsFetchingMore(true);
            setTimeout(() => {
                const responsesToAdd = Math.min(
                    selectedResponses.length - lazyLoadedResponses.length,
                    lazyLoadingIncrement,
                );
                setLazyLoadedResponsesCount(lazyLoadedResponsesCount + responsesToAdd);
                setIsFetchingMore(false);
            }, 1000);
        }
    };

    return (
        <div className={`short_answer_sec ${getActivityComponentClassName(activityType)}`}>
            <div className="shortAnswerContainer">
                {activityType !== utilConstants.ACTIVITY_TYPE.VIDEO_UPLOAD && (
                    <div className="search_area">
                        <SearchBox onSearch={onSearch} />
                        <ShowingResponsesCount count={selectedResponses.length} />
                    </div>
                )}
                <div className="short_ans_wrap" id="activityCommonDiv" onScroll={(e) => handleScroll(e)}>
                    {activityType === utilConstants.ACTIVITY_TYPE.SHORT_ANSWER && (
                        <ShortAnswerContent
                            selectedResponses={selectedResponses}
                            searchKeyword={searchKeyword}
                            isVoting={isVoting}
                            isVotingNamesHidden={isVotingNamesHidden}
                            onThumbClicked={!isGivePointsToAll ? handleThumbClick : () => {}}
                            sortSelectedResponses={sortSelectedResponses}
                        />
                    )}
                    {activityType === utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING && (
                        <SlideDrawingContent
                            selectedResponses={lazyLoadedResponses}
                            searchKeyword={searchKeyword}
                            isVoting={isVoting}
                            onThumbClicked={!isGivePointsToAll ? handleThumbClick : () => {}}
                            sortSelectedResponses={sortSelectedResponses}
                            isFetchingMore={isFetchingMore}
                        />
                    )}
                    {activityType === utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD && (
                        <ImageUploadContent
                            selectedResponses={lazyLoadedResponses}
                            searchKeyword={searchKeyword}
                            isVoting={isVoting}
                            onThumbClicked={!isGivePointsToAll ? handleThumbClick : () => {}}
                            sortSelectedResponses={sortSelectedResponses}
                            isFetchingMore={isFetchingMore}
                        />
                    )}
                    {activityType === utilConstants.ACTIVITY_TYPE.AUDIO_RECORD && (
                        <AudioRecordContent
                            selectedResponses={lazyLoadedResponses}
                            searchKeyword={searchKeyword}
                            isVoting={isVoting}
                            sortSelectedResponses={sortSelectedResponses}
                            isFetchingMore={isFetchingMore}
                        />
                    )}
                    {activityType === utilConstants.ACTIVITY_TYPE.VIDEO_UPLOAD && (
                        <VideoUploadContent
                            selectedResponses={selectedResponses}
                            searchKeyword={searchKeyword}
                            isVoting={isVoting}
                            onSearch={onSearch}
                            isGivePointsToAll={isGivePointsToAll}
                        />
                    )}
                </div>
                {choosedResponse && (
                    <MultiMediaActivityModal
                        selectedResponses={selectedResponses}
                        choosedResponse={choosedResponse}
                        setChoosedResponse={setChoosedResponse}
                        responseIndex={modalResponseIndex}
                        setResponseIndex={setModalResponseIndex}
                        onResponseIndexDeleted={handleResponseIndexDeletedFromModal}
                        lazyLoadedResponsesCount={lazyLoadedResponsesCount}
                        onShowMore={checkAndShowMoreResponses}
                        searchKeyword={searchKeyword}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        activityType={activityType}
                        isVoting={isVoting}
                    />
                )}
            </div>
        </div>
    );
}
